.profile {
  padding: 1rem;
  height: 100%;
}

.profile .avatar {
  width: 150px;
  height: 150px;
}

.profile .header {
  padding: 1rem;
}
.profile .friendList {
  max-height: 30rem;
  overflow: auto;
}